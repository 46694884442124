/* .evidence-table {
  border-collapse: collapse;
} */

.evidence-table th,
.evidence-table td {
  border: 1px solid #dee2e6;
  text-align: left;
  padding: 8px;
}

.evidence-table tbody tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.evidence-table tbody tr:hover {
  background-color: #c3ffd752;
}

.modal-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}

.filtered-count {
  font-size: 13px;
  color: #333;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-end;
}

.download-button {
  background: none;
  border: none;
  cursor: pointer;
}

.save-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  margin-top: 10px;
}

.save-button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.save-button:hover {
  background-color: #45a049;
}

.save-button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}
