.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.login-card {
  background: linear-gradient(135deg, #e9f5f9 0%, #feffff 100%);
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 350px;
}

.login-button {
  background-color: #46a68f;
  color: white;
  padding: 1rem 2rem;
  margin-top: 2rem;
  border: none;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  background-image: linear-gradient(to right, #46a68f, #4db6a4);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: none;
}

.login-button:hover {
  background-image: linear-gradient(to right, #4db6a4, #53c7b0);
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.login-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(70, 166, 143, 0.5);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}
