.TagRuleFilterContainer {
    --primary-color: rgb(40 164 144 / 1);
    background-color: white;
    position: absolute;
    top: 50%;
    right: 50%;
    border-radius: 4px;
    transform: translate(50%, -50%);
    width: 50%;
    height: 500px;
}
.TagRuleFilterContainer .MuiTableContainer-root {
    height: 375px;
    overflow: auto;
}
.TagRuleFilterContainer > h4 {
    margin: auto;
    font-size: 20px;
    padding: 20px;
    background-color: #f5f5f5;
}
.TagRuleFilterFooter {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.TagRuleFilterFooter button {
  text-transform: none;
  background-color: white;
  border: 1px solid var(--primary-color);
  font-weight: bold;
  padding: 5px 15px;
  color: var(--primary-color);
}
.TagRuleFilterFooter button[type="submit"] {
    background-color: var(--primary-color);
    color: white;
}
