.label-container {
  background-color: #f4f4f4;
  border-radius: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.label-list {
  padding: 2px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 25vw;
}

.button-group {
  display: flex;
  flex-direction: row;
}

.modal-labels {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-content-labels {
  background-color: #ffffff;
  margin: 16px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 60%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.close-labels {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-labels:hover,
.close-labels:focus {
  color: black;
  cursor: pointer;
}

button-labels {
  margin-top: 8px;
  margin-right: 8px;
}

button.edit-button {
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px 0 0 4px;
  font-size: 15px;
  display: flex;
  padding: 5px 13px;
  width: 9vw;
}

.button-delete-button-labels {
  background-color: #ffc3c3;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  display: flex;
  font-size: 15px;
  padding: 5px 13px;
  justify-content: center;
  align-items: center;
}
.tag-failed-label {
  background-color: #f1c40f;
  color: white;
  border: none;
  border-radius: 4px;
  display: flex;
  font-size: 15px;
  padding: 5px 13px;
  justify-content: center;
  align-items: center;
}
.button-tag-failed-button-labels > svg {
  margin-right: 0.25vw;
}

.button-edit-button-labels {
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  padding: 5px 13px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.button-edit-button-labels:hover {
  background-color: #2d87c3;
  transition: 0.4s;
}

button.add-value-button {
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 8px;
  cursor: pointer;
  border-radius: 4px;
}

button.close-button {
  background-color: #7f8c8d;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 8px;
  cursor: pointer;
  border-radius: 4px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -50px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.no-tags-placeholder {
  text-align: left;
  background-color: #f2f2f2;
  border-radius: 8px;
  color: #333;
  font-size: 0.7vw;
  font-weight: 600;
  opacity: 0.7;
}
