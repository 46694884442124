.DataGraph {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  transition: all 0.3s ease;
}

.DataGraphHeader {
  width: 100%;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 30;
}

.DataGraphChipContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 1vw;
  gap: 4px;
  overflow: auto;
}

.DataGraphChip {
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5vh 0.5vw;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  height: 30px;
  padding: 0 12px;
  background-color: #f2f2f2;
  color: #333;
}

.DataGraphChip:hover {
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.bar-class {
  padding: 20px;
  max-height: 25vh;
  width: 28vw;
  font-weight: 700;
}

.bar-class-expanded {
  padding: 20px;
  max-height: 50vh;
  font-weight: 700;
}

.expand-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  background: none;
  border: none;
  cursor: pointer;
  transform: scale(1.5);
}

.modalOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  overflow: auto;
  background: #fff;
  box-sizing: border-box;
  border-radius: 10px;
}

.closeButton {
  z-index: 1001;
  transform: scale(0.9);
}
