@tailwind base;
@tailwind components;
@tailwind utilities;

.Home {
  position: fixed;
  inset: 0;
  overflow: hidden;
  display: flex;
  background-color: white;
  overflow: hidden;
  flex-direction: column;
}

.LoadComponentContainer {
  display: flex;
  height: 80vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.home-label-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 5vh;
}

.home-label-parent-container {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.home-label-title {
  /* font-size: 1.1vw; */
  font-size: 16px;
  font-weight: 600;
  color: #505e71;
  margin-bottom: 20px;
  margin-top: 1vh;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
