.TeamManagerLoadingIndicator {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.TeamManagerContainer {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 90vh;
}

.TeamManagerContainer > h4 {
  margin: 0 0 20px 0;
  padding: 15px;
  background-color: #3f51b5;
  color: white;
  text-align: center;
  border-radius: 8px 8px 0 0;
}

.TeamManagerFooter {
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.TeamManagerFooter > button[type="submit"] {
  background-color: #28a490;
  color: white;
}

.TeamManagerFooter > button[type="submit"]:hover {
  background-color: #1f8b79;
}

.TeamManagerFooter > button[type="reset"] {
  color: #666;
  border: 1px solid #666;
}

.TeamManagerFooter > button[type="reset"]:hover {
  background-color: #f5f5f5;
}
